import React, { Component } from "react";
import {
  ListGroup,
  ListGroupItem,
  Grid,
  Row,
  Col,
  Tabs,
  Tab,
  Panel,
  Button,
  Collapse,
  Form,
  FormGroup,
  FormControl,
  Glyphicon,
  Alert
} from "react-bootstrap";
//import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import uuid from "uuid";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedProject: null,
      projects: [],
      notesOpen: true,
      tasksOpen: true,
      projectsOpen: true,
      debugOpen: false,
      newProjectName: "",
      newTaskName: ""
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const projects = await this.projects();
      this.setState({ projects });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  projects() {
    //return API.get("projects", "/projects");
    //return this.state.projects;
    return API.get("projects", "/projects/Everything").catch(reason => [
      {
        name: "demo",
        id: "demoprojectid",
        tasks: [{ taskName: "task name demo", id: "demotaskid" }],
        notes: [
          {
            tabName: "tab name",
            id: "tabid",
            pages: [
              {
                pageName: "page name",
                id: "pageid",
                content: "this is the text"
              }
            ]
          }
        ]
      }
    ]);
  }

  renderProjectsList(projects) {
    return projects.map(project => (
      <ListGroupItem
        header={project.name}
        key={project.id}
        active={project.id === this.state.selectedProject}
        onClick={() => this.setState({ selectedProject: project.id })}
      />
    ));
  }

  renderTasksList(selectedProject) {
    if (!selectedProject) return [];
    const project = this.state.projects.filter(p => p.id === selectedProject);
    if (project && project.length > 0 && project[0].tasks)
      return project[0].tasks.map(t => (
        <ListGroupItem key={t.id} header={t.taskName} />
      ));
  }

  renderNotesTabsList(selectedProject) {
    if (!selectedProject) return [];
    const project = this.state.projects.filter(p => p.id === selectedProject);
    if (project && project.length > 0 && project[0].notes)
      return project[0].notes.map(t => (
        <Tab key={t.id} eventKey={t.id} title={t.tabName}>
          <Tabs id={"Pages" + t.id}>
            {(t.pages || []).map(p => (
              <Tab key={1} eventKey={1} title={p.pageName}>
                <textarea
                  value={p.content}
                  onChange={() => (p.content = "get the value somehow")}
                />
              </Tab>
            ))}
          </Tabs>
        </Tab>
      ));
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Simplex Suite</h1>
        <p>Simplifying the complex. (Stream-lined deployment)</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderProjects() {
    return (
      <div className="projects">
        <Alert bsStyle="danger">
          <h4>Nothing is saved!</h4>
        </Alert>
        <Grid fluid="true">
          <Row className="show-grid">
            <Col md={this.state.projectsOpen ? 4 : 1}>
              <Panel>
                <h4
                  onClick={() =>
                    this.setState({
                      projectsOpen: !this.state.projectsOpen
                    })
                  }
                >
                  <Glyphicon
                    glyph={this.state.projectsOpen ? "minus" : "plus"}
                  />
                  {" Projects"}
                </h4>
                <Collapse in={this.state.projectsOpen}>
                  <ListGroup>
                    {!this.state.isLoading && (
                      <ListGroupItem key="newproject">
                        <Form inline>
                          <FormGroup controlId="addProjectTextbox">
                            <FormControl
                              placeholder="add project..."
                              value={this.state.newProjectName}
                              onChange={e => {
                                this.setState({
                                  newProjectName: e.target.value
                                });
                              }}
                            />
                          </FormGroup>{" "}
                          <Button
                            onClick={e => {
                              if (!this.state.newProjectName) return;
                              const guid = uuid.v1();
                              this.setState({
                                projects: [
                                  ...this.state.projects,
                                  {
                                    name: this.state.newProjectName,
                                    id: guid
                                  }
                                ],
                                selectedProject: guid
                              });
                            }}
                          >
                            Add
                          </Button>
                        </Form>
                      </ListGroupItem>
                    )}
                    {!this.state.isLoading &&
                      this.renderProjectsList(this.state.projects)}
                  </ListGroup>
                </Collapse>
              </Panel>
            </Col>
            <Col md={this.state.projectsOpen ? 8 : 11}>
              <Panel>
                <h4
                  onClick={() =>
                    this.setState({
                      tasksOpen: !this.state.tasksOpen
                    })
                  }
                >
                  <Glyphicon glyph={this.state.tasksOpen ? "minus" : "plus"} />
                  {" Tasks"}
                </h4>
                <Collapse in={this.state.tasksOpen}>
                  <ListGroup>
                    {!this.state.isLoading && (
                      <ListGroupItem key="newtask">
                        <Form inline>
                          <FormGroup controlId="addTaskTextbox">
                            <FormControl
                              type="text"
                              placeholder="add task..."
                              value={this.state.newTaskName}
                              onChange={e => {
                                this.setState({
                                  newTaskName: e.target.value
                                });
                              }}
                            />
                          </FormGroup>
                          <Button
                            onClick={e => {
                              if (
                                !this.state.newTaskName ||
                                !this.state.selectedProject
                              )
                                return;
                              this.setState({
                                projects: this.state.projects.map(p => {
                                  if (p.id === this.state.selectedProject) {
                                    p.tasks = p.tasks || [];
                                    p.tasks.push({
                                      taskName: this.state.newTaskName,
                                      id: uuid.v1()
                                    });
                                  }

                                  return p;
                                })
                              });
                            }}
                          >
                            Add
                          </Button>
                        </Form>
                      </ListGroupItem>
                    )}
                    {!this.state.isLoading &&
                      this.renderTasksList(this.state.selectedProject)}
                  </ListGroup>
                </Collapse>
              </Panel>
              <Panel>
                <h4
                  onClick={() =>
                    this.setState({
                      notesOpen: !this.state.notesOpen
                    })
                  }
                >
                  <Glyphicon glyph={this.state.notesOpen ? "minus" : "plus"} />
                  {" Notes"}
                </h4>
                <Collapse in={this.state.notesOpen}>
                  <Tabs
                    id={"Project" + this.state.selectedProject + "NotesTabs"}
                  >
                    {!this.state.isLoading &&
                      this.renderNotesTabsList(this.state.selectedProject)}
                  </Tabs>
                </Collapse>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Panel>
                <h4
                  onClick={() =>
                    this.setState({ debugOpen: !this.state.debugOpen })
                  }
                >
                  <Glyphicon glyph={this.state.debugOpen ? "minus" : "plus"} />
                  {" Debug"}
                </h4>
                <Collapse in={this.state.debugOpen}>
                  <textarea
                    className="debugTextArea"
                    value={JSON.stringify(this.state.projects)}
                    onChange={() => this.setState({ projects: JSON.parse() })}
                  />
                </Collapse>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated
          ? this.renderProjects()
          : this.renderLander()}
      </div>
    );
  }
}
